// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-page-templates-cinema-index-js": () => import("./../../../src/pageTemplates/Cinema/index.js" /* webpackChunkName: "component---src-page-templates-cinema-index-js" */),
  "component---src-page-templates-story-index-js": () => import("./../../../src/pageTemplates/Story/index.js" /* webpackChunkName: "component---src-page-templates-story-index-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cinemap-js": () => import("./../../../src/pages/cinemap.js" /* webpackChunkName: "component---src-pages-cinemap-js" */),
  "component---src-pages-cinesearch-js": () => import("./../../../src/pages/cinesearch.js" /* webpackChunkName: "component---src-pages-cinesearch-js" */),
  "component---src-pages-cinestories-js": () => import("./../../../src/pages/cinestories.js" /* webpackChunkName: "component---src-pages-cinestories-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contribute-js": () => import("./../../../src/pages/contribute.js" /* webpackChunkName: "component---src-pages-contribute-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

